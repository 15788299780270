import { bannerData } from "../../../globals/banner";
import Banner from "../../sections/common/banner";
import SectionClients2 from "../../sections/home/index2/section-clients2";

function TestimonialsPage() {
    return (
        <>
            <Banner _data={bannerData.testimonials} />

            <div className="section-full p-t100 p-b0 bg-white mobile-page-padding">
                {/* TESTIMONIAL START */}
                <div className="container">
                    <div className="section-content">
                        {/* TITLE START */}
                        <div className="section-head center">
                            <div className="sx-head-s-title">Our Testimonials</div>
                            <div className="sx-head-l-title">
                                <h2 className="sx-title">What Clients Say?</h2>
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="masonry-outer mfp-gallery news-grid clearfix row">
                            {/* COLUMNS 1 */}
                            <div className="masonry-item  col-lg-4 col-md-6 col-sm-12 m-b30">
                                <div className="testimonial-3  hover-animation-1 sx-bg-light">
                                    <div className="testimonial-detail">
                                        <div className="testimonial-user-info">
                                            <span className="testimonial-position">Founder Of Companey</span>
                                            <h4 className="testimonial-name">Oliver Eliah	Charlotte</h4>
                                        </div>
                                    </div>
                                    <div className="testimonial-text">
                                        <p>Nullam cursus lacus insr veneni atis estas. Fusceri libero lorem, vehicul ege sapien id, faucibus vulp. Donec ut urna pretiumiss, ferment felis eget, cons exos. In etcou fringil aliber.</p>
                                    </div>
                                    <div className="testimonial-footer">
                                        <div className="testimonial-quote sx-text-primary">
                                            <i className="flaticon-quote" />
                                        </div>
                                        <ul className="sx-rating-st1">
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                        </ul>
                                        <span className="sx-review-count">(5 Reviews)</span>
                                    </div>
                                </div>
                            </div>
                            {/* COLUMNS 2 */}
                            <div className="masonry-item  col-lg-4 col-md-6 col-sm-12 m-b30">
                                <div className="testimonial-3  hover-animation-1 sx-bg-light">
                                    <div className="testimonial-detail">
                                        <div className="testimonial-user-info">
                                            <span className="testimonial-position">Founder Of intheloop.io</span>
                                            <h4 className="testimonial-name">Zahra Biabani</h4>
                                        </div>
                                    </div>
                                    <div className="testimonial-text">
                                        <p>It was amazing to work with. Their team was incredibly responsive and took personal ownership to integrate with our team and execute tasks that surpassed our expectations. We will definitely be working with them again and highly recommend hiring them!</p>
                                    </div>
                                    <div className="testimonial-footer">
                                        <div className="testimonial-quote sx-text-primary">
                                            <i className="flaticon-quote" />
                                        </div>
                                        <ul className="sx-rating-st1">
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                        </ul>
                                        <span className="sx-review-count">(5 Reviews)</span>
                                    </div>
                                </div>
                            </div>
                            {/* COLUMNS 3 */}
                            <div className="masonry-item  col-lg-4 col-md-6 col-sm-12 m-b30">
                                <div className="testimonial-3  hover-animation-1 sx-bg-light">
                                    <div className="testimonial-detail">
                                        <div className="testimonial-user-info">
                                            <span className="testimonial-position">Founder Of Companey</span>
                                            <h4 className="testimonial-name">Oliver Eliah	Charlotte</h4>
                                        </div>
                                    </div>
                                    <div className="testimonial-text">
                                        <p>Nullam cursus lacus insr veneni atis estas. Fusceri libero lorem, vehicul ege sapien id, faucibus vulp. Donec ut urna pretiumiss, ferment felis eget, cons exos. In etcou fringil aliber.</p>
                                    </div>
                                    <div className="testimonial-footer">
                                        <div className="testimonial-quote sx-text-primary">
                                            <i className="flaticon-quote" />
                                        </div>
                                        <ul className="sx-rating-st1">
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                        </ul>
                                        <span className="sx-review-count">(5 Reviews)</span>
                                    </div>
                                </div>
                            </div>
                            {/* COLUMNS 4 */}
                            <div className="masonry-item  col-lg-4 col-md-6 col-sm-12 m-b30">
                                <div className="testimonial-3  hover-animation-1 sx-bg-light">
                                    <div className="testimonial-detail">
                                        <div className="testimonial-user-info">
                                            <span className="testimonial-position">Founder Of Companey</span>
                                            <h4 className="testimonial-name">Delilah Brooklyn</h4>
                                        </div>
                                    </div>
                                    <div className="testimonial-text">
                                        <p>Nullam cursus lacus insr veneni atis estas. Fusceri libero lorem, vehicul ege sapien id, faucibus vulp. Donec ut urna pretiumiss, ferment felis eget, cons exos. In etcou fringil aliber.</p>
                                    </div>
                                    <div className="testimonial-footer">
                                        <div className="testimonial-quote sx-text-primary">
                                            <i className="flaticon-quote" />
                                        </div>
                                        <ul className="sx-rating-st1">
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                        </ul>
                                        <span className="sx-review-count">(5 Reviews)</span>
                                    </div>
                                </div>
                            </div>
                            {/* COLUMNS 5 */}
                            <div className="masonry-item  col-lg-4 col-md-6 col-sm-12 m-b30">
                                <div className="testimonial-3  hover-animation-1 sx-bg-light">
                                    <div className="testimonial-detail">
                                        <div className="testimonial-user-info">
                                            <span className="testimonial-position">Founder Of Companey</span>
                                            <h4 className="testimonial-name">Oliver Eliah	Charlotte</h4>
                                        </div>
                                    </div>
                                    <div className="testimonial-text">
                                        <p>Nullam cursus lacus insr veneni atis estas. Fusceri libero lorem, vehicul ege sapien id, faucibus vulp. Donec ut urna pretiumiss, ferment felis eget, cons exos. In etcou fringil aliber.</p>
                                    </div>
                                    <div className="testimonial-footer">
                                        <div className="testimonial-quote sx-text-primary">
                                            <i className="flaticon-quote" />
                                        </div>
                                        <ul className="sx-rating-st1">
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                        </ul>
                                        <span className="sx-review-count">(5 Reviews)</span>
                                    </div>
                                </div>
                            </div>
                            {/* COLUMNS 6 */}
                            <div className="masonry-item  col-lg-4 col-md-6 col-sm-12 m-b30">
                                <div className="testimonial-3  hover-animation-1 sx-bg-light">
                                    <div className="testimonial-detail">
                                        <div className="testimonial-user-info">
                                            <span className="testimonial-position">Founder Of Companey</span>
                                            <h4 className="testimonial-name">Delilah Brooklyn</h4>
                                        </div>
                                    </div>
                                    <div className="testimonial-text">
                                        <p>Nullam cursus lacus insr veneni atis estas. Fusceri libero lorem, vehicul ege sapien id, faucibus vulp. Donec ut urna pretiumiss, ferment felis eget, cons exos. In etcou fringil aliber.</p>
                                    </div>
                                    <div className="testimonial-footer">
                                        <div className="testimonial-quote sx-text-primary">
                                            <i className="flaticon-quote" />
                                        </div>
                                        <ul className="sx-rating-st1">
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                            <li><span><i className="fa fa-star" /></span></li>
                                        </ul>
                                        <span className="sx-review-count">(5 Reviews)</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* TESTIMONIAL END */}
            </div>

            <SectionClients2 />
        </>
    )
}

export default TestimonialsPage;