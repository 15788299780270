function SectionTestimonials2() {
    return (
        <>
            <div className="sx-testimonial-bx-2  m-b30">
                {/* TITLE START */}
                <div className="section-head center">
                    <div className="sx-head-s-title">Our Testimonials</div>
                    <div className="sx-head-l-title">
                        <h2 className="sx-title">What Clients Say?</h2>
                    </div>
                </div>
                {/* TITLE END */}
                <div className="owl-carousel testimonial-section-2 owl-btn-vertical-center">
                    {/*One block*/}
                    <div className="item">
                        <div className="testimonial-1  hover-animation-1 sx-bg-white">
                            <div className="testimonial-detail">
                                <div className="testimonial-user-info">
                                    <span className="testimonial-position">
                                        Founder of <a href="https://tesorahub.com" target="_blank" rel="noreferrer">Tesora Group</a>
                                    </span>
                                    <h4 className="testimonial-name">Jaime Villagomez</h4>
                                </div>
                            </div>
                            <div className="testimonial-text">
                                <p>They are awesome. They have solved every AWS problem we've faced over the past 7 years. The team is honest and trustworthy, handling a dozen of our projects with great care. Their expertise in setting up a microservice architecture using AWS EKS was remarkable, making our platform scalable and reliable..</p>
                            </div>
                            <div className="testimonial-footer">
                                <div className="testimonial-quote sx-text-primary">
                                    <i className="flaticon-quote" />
                                </div>
                                <ul className="sx-rating-st1">
                                    <li><span><i className="fa fa-star" /></span></li>
                                    <li><span><i className="fa fa-star" /></span></li>
                                    <li><span><i className="fa fa-star" /></span></li>
                                    <li><span><i className="fa fa-star" /></span></li>
                                    <li><span><i className="fa fa-star" /></span></li>
                                </ul>
                                <span className="sx-review-count">(5 Reviews)</span>
                            </div>
                        </div>
                    </div>
                    {/*One two*/}
                    <div className="item">
                        <div className="testimonial-1  hover-animation-1 sx-bg-white">
                            <div className="testimonial-detail">
                                <div className="testimonial-user-info">
                                    <span className="testimonial-position">
                                        Founder of <a href="https://www.intheloop.io" target="_blank" rel="noreferrer">intheloop.io</a>
                                    </span>
                                    <h4 className="testimonial-name">Zahra Biabani</h4>
                                </div>
                            </div>
                            <div className="testimonial-text">
                                <p>It was amazing to work with. Their team was incredibly responsive and took personal ownership to integrate with our team and execute tasks that surpassed our expectations. We will definitely be working with them again and highly recommend hiring them!</p>
                            </div>
                            <div className="testimonial-footer">
                                <div className="testimonial-quote sx-text-primary">
                                    <i className="flaticon-quote" />
                                </div>
                                <ul className="sx-rating-st1">
                                    <li><span><i className="fa fa-star" /></span></li>
                                    <li><span><i className="fa fa-star" /></span></li>
                                    <li><span><i className="fa fa-star" /></span></li>
                                    <li><span><i className="fa fa-star" /></span></li>
                                    <li><span><i className="fa fa-star" /></span></li>
                                </ul>
                                <span className="sx-review-count">(5 Reviews)</span>
                            </div>
                        </div>
                    </div>
                    {/*One three*/}
                    {/* <div className="item">
                        <div className="testimonial-1  hover-animation-1 sx-bg-white">
                            <div className="testimonial-detail">
                                <div className="testimonial-user-info">
                                    <span className="testimonial-position">Founder Of Companey</span>
                                    <h4 className="testimonial-name">Oliver Eliah	Charlotte</h4>
                                </div>
                            </div>
                            <div className="testimonial-text">
                                <p>Nullam cursus lacus insr veneni atis estas. libero lorem, vehicul ege sapien id, faucibus vulp. Donec ut urna pretiumiss, ferment felis eget, cons exos. In etcou fringil aliber.</p>
                            </div>
                            <div className="testimonial-footer">
                                <div className="testimonial-quote sx-text-primary">
                                    <i className="flaticon-quote" />
                                </div>
                                <ul className="sx-rating-st1">
                                    <li><span><i className="fa fa-star" /></span></li>
                                    <li><span><i className="fa fa-star" /></span></li>
                                    <li><span><i className="fa fa-star" /></span></li>
                                    <li><span><i className="fa fa-star" /></span></li>
                                    <li><span><i className="fa fa-star" /></span></li>
                                </ul>
                                <span className="sx-review-count">(5 Reviews)</span>
                            </div>
                        </div>
                    </div> */}
                    {/*One four*/}
                    {/* <div className="item">
                        <div className="testimonial-1  hover-animation-1 sx-bg-white">
                            <div className="testimonial-detail">
                                <div className="testimonial-user-info">
                                    <span className="testimonial-position">Founder Of Companey</span>
                                    <h4 className="testimonial-name">Delilah Brooklyn</h4>
                                </div>
                            </div>
                            <div className="testimonial-text">
                                <p>Nullam cursus lacus insr veneni atis estas. libero lorem, vehicul ege sapien id, faucibus vulp. Donec ut urna pretiumiss, ferment felis eget, cons exos. In etcou fringil aliber.</p>
                            </div>
                            <div className="testimonial-footer">
                                <div className="testimonial-quote sx-text-primary">
                                    <i className="flaticon-quote" />
                                </div>
                                <ul className="sx-rating-st1">
                                    <li><span><i className="fa fa-star" /></span></li>
                                    <li><span><i className="fa fa-star" /></span></li>
                                    <li><span><i className="fa fa-star" /></span></li>
                                    <li><span><i className="fa fa-star" /></span></li>
                                    <li><span><i className="fa fa-star" /></span></li>
                                </ul>
                                <span className="sx-review-count">(5 Reviews)</span>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default SectionTestimonials2;