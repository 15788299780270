import { NavLink } from "react-router-dom";

function SectionServices2() {
    return (
        <>
            <div className="section-full p-t110 p-b80 sx-why-choose-outer sx-bg-light">
                <div className="container">
                    {/* TITLE START */}
                    <div className="section-head center">
                        <div className="sx-head-s-title">Why Choose Us</div>
                        <div className="sx-head-l-title">
                            <h2 className="sx-title">Our Work Services</h2>
                        </div>
                    </div>
                    {/* TITLE END */}
                    <div className="section-content">
                        <div className="owl-carousel sx-why-choose-carousel m-b30">
                            {/*One block*/}
                            <div className="item">
                                <div className="sx-icon-bx-4">
                                    <div className="sx-icon-box-wraper">
                                        <div className="sx-icon-bx-icon ">
                                            <span className="sx-text-primary"><i className="flaticon-monitor" /></span>
                                        </div>
                                        <div className="icon-content">
                                            <NavLink to="/services/detail"><h4 className="sx-tilte">Expertise and Experience</h4></NavLink>
                                            <NavLink to="/services/detail" className="sx-why-ch-category">Skilled Team</NavLink>
                                            <p>Our team consists of experienced professionals in frontend, backend, and mobile development, ensuring high-quality and reliable solutions.</p>
                                            <NavLink className="sx-circle-btn" to="/services/detail"><i className="fa fa-long-arrow-right" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*One two*/}
                            <div className="item">
                                <div className="sx-icon-bx-4">
                                    <div className="sx-icon-box-wraper">
                                        <div className="sx-icon-bx-icon ">
                                            <span className="sx-text-primary"><i className="flaticon-mouse-1" /></span>
                                        </div>
                                        <div className="icon-content">
                                            <NavLink to="/services/detail"><h4 className="sx-tilte">Customer-Centric Approach</h4></NavLink>
                                            <NavLink to="/services/detail" className="sx-why-ch-category">Tailored Solutions</NavLink>
                                            <p>We understand that every business is unique. Our solutions are customized to meet the specific needs and goals of each client, ensuring maximum satisfaction.</p>
                                            <NavLink className="sx-circle-btn" to="/services/detail"><i className="fa fa-long-arrow-right" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*One three*/}
                            <div className="item">
                                <div className="sx-icon-bx-4">
                                    <div className="sx-icon-box-wraper">
                                        <div className="sx-icon-bx-icon ">
                                            <span className="sx-text-primary"><i className="flaticon-layers" /></span>
                                        </div>
                                        <div className="icon-content">
                                            <NavLink to="/services/detail"><h4 className="sx-tilte">Comprehensive Services</h4></NavLink>
                                            <NavLink to="/services/detail" className="sx-why-ch-category">End-to-End Solutions</NavLink>
                                            <p>We provide comprehensive development services, from initial planning and design to deployment and maintenance, ensuring a seamless experience for our clients.</p>
                                            <NavLink className="sx-circle-btn" to="/services/detail"><i className="fa fa-long-arrow-right" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*One four*/}
                            <div className="item">
                                <div className="sx-icon-bx-4">
                                    <div className="sx-icon-box-wraper">
                                        <div className="sx-icon-bx-icon ">
                                            <span className="sx-text-primary"><i className="flaticon-web-design" /></span>
                                        </div>
                                        <div className="icon-content">
                                            <NavLink to="/services/detail"><h4 className="sx-tilte">Quality and Reliability</h4></NavLink>
                                            <NavLink to="/services/detail" className="sx-why-ch-category">High-Quality Standards</NavLink>
                                            <p>Our commitment to quality is reflected in our meticulous attention to detail and rigorous testing processes, ensuring robust and bug-free applications.</p>
                                            <NavLink className="sx-circle-btn" to="/services/detail"><i className="fa fa-long-arrow-right" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*One five*/}
                            <div className="item">
                                <div className="sx-icon-bx-4">
                                    <div className="sx-icon-box-wraper">
                                        <div className="sx-icon-bx-icon ">
                                            <span className="sx-text-primary"><i className="flaticon-monitor" /></span>
                                        </div>
                                        <div className="icon-content">
                                            <NavLink to="/services/detail"><h4 className="sx-tilte">Innovation and Creativity</h4></NavLink>
                                            <NavLink to="/services/detail" className="sx-why-ch-category">Innovative Solutions</NavLink>
                                            <p>We are dedicated to innovation, constantly exploring new ideas and technologies to provide creative and effective solutions.</p>
                                            <NavLink className="sx-circle-btn" to="/services/detail"><i className="fa fa-long-arrow-right" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*One six*/}
                            <div className="item">
                                <div className="sx-icon-bx-4">
                                    <div className="sx-icon-box-wraper">
                                        <div className="sx-icon-bx-icon ">
                                            <span className="sx-text-primary"><i className="flaticon-mouse-1" /></span>
                                        </div>
                                        <div className="icon-content">
                                            <NavLink to="/services/detail"><h4 className="sx-tilte">Support and Maintenance</h4></NavLink>
                                            <NavLink to="/services/detail" className="sx-why-ch-category">Ongoing Support</NavLink>
                                            <p>Our relationship with clients doesn’t end with project delivery. We offer ongoing support and maintenance to ensure your applications run smoothly.</p>
                                            <NavLink className="sx-circle-btn" to="/services/detail"><i className="fa fa-long-arrow-right" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*One seven*/}
                            <div className="item">
                                <div className="sx-icon-bx-4">
                                    <div className="sx-icon-box-wraper">
                                        <div className="sx-icon-bx-icon ">
                                            <span className="sx-text-primary"><i className="flaticon-layers" /></span>
                                        </div>
                                        <div className="icon-content">
                                            <NavLink to="/services/detail"><h4 className="sx-tilte">Call to Action</h4></NavLink>
                                            <NavLink to="/services/detail" className="sx-why-ch-category">Get in Touch</NavLink>
                                            <p>Interested in working with us? Get in touch to discuss your project and see how we can help you achieve your vision.</p>
                                            <NavLink className="sx-circle-btn" to="/services/detail"><i className="fa fa-long-arrow-right" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*One eight*/}
                            <div className="item">
                                <div className="sx-icon-bx-4">
                                    <div className="sx-icon-box-wraper">
                                        <div className="sx-icon-bx-icon ">
                                            <span className="sx-text-primary"><i className="flaticon-web-design" /></span>
                                        </div>
                                        <div className="icon-content">
                                            <NavLink to="/services/detail"><h4 className="sx-tilte">Post-Launch Support</h4></NavLink>
                                            <NavLink to="/services/detail" className="sx-why-ch-category">Continuous Support</NavLink>
                                            <p>We offer comprehensive post-launch support, ensuring your applications remain up-to-date and running smoothly.</p>
                                            <NavLink className="sx-circle-btn" to="/services/detail"><i className="fa fa-long-arrow-right" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionServices2;